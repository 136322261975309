body {
  margin: 0;
  font-family: 'Roboto', sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

h1 {
  font-weight: 300;
}

h4 {
  font-weight: 300;
}

h5 {
  font-weight: 300;
}

h6 {
  font-weight: 300;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

.submit-button {
  padding: .5em 1em;
  color: white;
  background-color: #F65E59;
  border-radius: 4px;
  border-color: #F65E59;
  border-style: none;
}

.submit-button:disabled {
  padding: .5em 1em;
  color: rgba(0, 0, 0, 0.404);
  background-color: #cccccccc;
  border-radius: 4px;
  border-color: #cccccccc;
  border-style: none;
}

.submit-button:enabled:hover {
  padding: .5em 1em;
  color: white;
  background-color: #E94944;
  border-radius: 4px;
  border-color: #E94944;
  border-style: none;
}

.submit-button:active {
  padding: .5em 1em;
  color: white;
  background-color: #D12A25;
  border-radius: 4px;
  border-color: #D12A25;
  border-style: none;
  outline: none;
}

.canceling-button {
  padding: .5em 1em;
  color: white;
  background-color: #cccccccc;
  border-radius: 4px;
  border-color: #cccccccc;
  border-style: none;
}

.canceling-button:enabled {
  outline: none;
}

.canceling-button:enabled:hover {
  padding: .5em 1em;
  color: white;
  background-color: #afacaccc;
  border-radius: 4px;
  border-color: #afacaccc;
  border-style: none;
}

.canceling-button:active {
  padding: .5em 1em;
  color: white;
  background-color: #969393cc;
  border-radius: 4px;
  border-color: #969393cc;
  border-style: none;
  outline: none;
}

.password-icon {
  position: absolute;
  top: 39px;
  right: 20px;
  cursor: pointer;
}

.success {
  color: green;
}

.success-complete {
  color: green;
  padding: .5em 0 .5em 0;
  border: solid 1px rgba(0, 128, 0, 0.658);
  border-radius: 3px;
  background-color: rgba(0, 128, 0, 0.075);
}

.register-form {
  background-color: #f9f9f9;
  border-radius: 4px;
  box-shadow: 0px 0px 40px #00000020;
}